export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST'
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS'
export const USER_UPDATE_PASSWORD_FAIL = 'USER_UPDATE_PASSWORD_FAIL'

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'

export const USER_CONFIRM_REGISTRATION_REQUEST =
  'USER_CONFIRM_REGISTRATION_REQUEST'
export const USER_CONFIRM_REGISTRATION_SUCCESS =
  'USER_CONFIRM_REGISTRATION_SUCCESS'
export const USER_CONFIRM_REGISTRATION_FAIL = 'USER_CONFIRM_REGISTRATION_FAIL'

export const USER_RESET_PASSWORD_VALID_REQUEST =
  'USER_RESET_PASSWORD_VALID_REQUEST'
export const USER_RESET_PASSWORD_VALID_SUCCESS =
  'USER_RESET_PASSWORD_VALID_SUCCESS'
export const USER_RESET_PASSWORD_VALID_FAIL = 'USER_RESET_PASSWORD_VALID_FAIL'

export const USER_CONFIRM_REGISTRATION_VALID_REQUEST =
  'USER_CONFIRM_REGISTRATION_VALID_REQUEST'
export const USER_CONFIRM_REGISTRATION_VALID_SUCCESS =
  'USER_CONFIRM_REGISTRATION_VALID_SUCCESS'
export const USER_CONFIRM_REGISTRATION_VALID_FAIL =
  'USER_CONFIRM_REGISTRATION_VALID_FAIL'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const USER_REMOVE_REQUEST = 'USER_REMOVE_REQUEST'
export const USER_REMOVE_SUCCESS = 'USER_REMOVE_SUCCESS'
export const USER_REMOVE_FAIL = 'USER_REMOVE_FAIL'

export const USER_ADD_REQUEST = 'USER_ADD_REQUEST'
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS'
export const USER_ADD_FAIL = 'USER_ADD_FAIL'

export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST'
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS'
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL'
export const USER_EDIT_RESET = 'USER_EDIT_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'
