export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_PREVIOUS_PAGE = 'PRODUCT_PREVIOUS_PAGE'
export const PRODUCT_NEXT_PAGE = 'PRODUCT_NEXT_PAGE'

export const PRODUCT_REMOVE_REQUEST = 'PRODUCT_REMOVE_REQUEST'
export const PRODUCT_REMOVE_SUCCESS = 'PRODUCT_REMOVE_SUCCESS'
export const PRODUCT_REMOVE_FAIL = 'PRODUCT_REMOVE_FAIL'

export const PRODUCT_ADD_REQUEST = 'PRODUCT_ADD_REQUEST'
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS'
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL'

export const PRODUCT_EDIT_REQUEST = 'PRODUCT_EDIT_REQUEST'
export const PRODUCT_EDIT_SUCCESS = 'PRODUCT_EDIT_SUCCESS'
export const PRODUCT_EDIT_FAIL = 'PRODUCT_EDIT_FAIL'
export const PRODUCT_EDIT_RESET = 'PRODUCT_EDIT_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const FEATURED_PRODUCT_LIST_REQUEST = 'FEATURED_PRODUCT_LIST_REQUEST'
export const FEATURED_PRODUCT_LIST_SUCCESS = 'FEATURED_PRODUCT_LIST_SUCCESS'
export const FEATURED_PRODUCT_LIST_FAIL = 'FEATURED_PRODUCT_LIST_FAIL'
export const FEATURED_PRODUCT_LIST_RESET = 'FEATURED_PRODUCT_LIST_RESET'

export const FEATURED_PRODUCT_UPDATE_REQUEST = 'FEATURED_PRODUCT_UPDATE_REQUEST'
export const FEATURED_PRODUCT_UPDATE_SUCCESS = 'FEATURED_PRODUCT_UPDATE_SUCCESS'
export const FEATURED_PRODUCT_UPDATE_FAIL = 'FEATURED_PRODUCT_UPDATE_FAIL'
export const FEATURED_PRODUCT_UPDATE_RESET = 'FEATURED_PRODUCT_UPDATE_RESET'

export const PRODUCT_LIKE_REQUEST = 'PRODUCT_LIKE_REQUEST'
export const PRODUCT_LIKE_SUCCESS = 'PRODUCT_LIKE_SUCCESS'
export const PRODUCT_LIKE_FAIL = 'PRODUCT_LIKE_FAIL'
export const PRODUCT_LIKE_RESET = 'PRODUCT_LIKE_RESET'

export const PRODUCT_WISHLIST_REQUEST = 'PRODUCT_WISHLIST_REQUEST'
export const PRODUCT_WISHLIST_SUCCESS = 'PRODUCT_WISHLIST_SUCCESS'
export const PRODUCT_WISHLIST_FAIL = 'PRODUCT_WISHLIST_FAIL'
export const PRODUCT_WISHLIST_RESET = 'PRODUCT_WISHLIST_RESET'

export const REVIEW_REMOVE_REQUEST = 'REVIEW_REMOVE_REQUEST'
export const REVIEW_REMOVE_SUCCESS = 'REVIEW_REMOVE_SUCCESS'
export const REVIEW_REMOVE_FAIL = 'REVIEW_REMOVE_FAIL'
export const REVIEW_REMOVE_RESET = 'REVIEW_REMOVE_RESET'

export const REVIEW_EDIT_REQUEST = 'REVIEW_EDIT_REQUEST'
export const REVIEW_EDIT_SUCCESS = 'REVIEW_EDIT_SUCCESS'
export const REVIEW_EDIT_FAIL = 'REVIEW_EDIT_FAIL'
export const REVIEW_EDIT_RESET = 'REVIEW_EDIT_RESET'

export const REVIEW_UPDATE_REQUEST = 'REVIEW_UPDATE_REQUEST'
export const REVIEW_UPDATE_SUCCESS = 'REVIEW_UPDATE_SUCCESS'
export const REVIEW_UPDATE_FAIL = 'REVIEW_UPDATE_FAIL'
export const REVIEW_UPDATE_RESET = 'REVIEW_UPDATE_RESET'

export const PRODUCT_MY_REVIEWS_REQUEST = 'PRODUCT_MY_REVIEWS_REQUEST'
export const PRODUCT_MY_REVIEWS_SUCCESS = 'PRODUCT_MY_REVIEWS_SUCCESS'
export const PRODUCT_MY_REVIEWS_FAIL = 'PRODUCT_MY_REVIEWS_FAIL'
export const PRODUCT_MY_REVIEWS_RESET = 'PRODUCT_MY_REVIEWS_RESET'
